export const filterPackages = (locationName, packageDetails) => {
  if (!packageDetails?.packages) {
    return [];
  }
  return locationName.toLowerCase().includes("garri")
    ? packageDetails.packages.filter(
      (pkg) =>
        !["kælivara", "matvara", "frostvara"].includes(pkg.name.toLowerCase())
    )
    : packageDetails.packages;
};