import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router";
import styled from "styled-components";
import useFetch from "use-http";
import Header from "../components/header/Header";
import PackageDescription from "../components/package/PackageDescription";
import Address from "../components/address/Address";
import Status from "../components/status/Status";
import DriverDetails from "../components/driverDetails/DriverDetails";
import NotFound from "../components/NotFound/NotFound";
import Loader from "../components/loader/Loader";
import DownloadFreightBill from "../components/downloadFreightBill/DownloadFreightBill";
import getFreightBillName from "../components/downloadFreightBill/functions";
import { useInterval } from "../hooks/useInterval";
import DeliveryProof from "../components/deliveryProof/DeliveryProof";
import * as Tracking from "../tracking/Tracking";
import { useTranslation } from "react-i18next";


const MainSection = styled.section`
  min-height: 100vh;
`;

const InfoContainer = styled.div`
  padding: 0px 100px;

  @media screen and (max-width: 39.9375em) {
    & {
      padding: 0px 0px;
    }
  }

  @media screen and (min-width: 40em) and (max-width: 63.9375em) {
    & {
      padding: 0px 20px;
    }
  }
`;

const StatusWrapper = styled.div`
  background-color: ${(props) => props.theme.colors.resolutionBlue};
  height: 100%;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
  display: flex;
  align-items: center;
  padding: 0px 100px 0px 140px;
  min-height: 100vh;
  position: fixed;

  @media screen and (max-width: 39.9375em) {
    padding: 0px;
    border-radius: 16px 16px 0px 0px;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: auto;
    z-index: 999;
    min-height: auto;
    background: transparent;
  }
  @media screen and (min-width: 40em) and (max-width: 63.9375em) {
    & {
      padding: 0px 20px 0px 60px;
    }
  }
`;

const OrderPage = () => {
  const { trackingID } = useParams();

  const [notFound, setNotFound] = useState(false);

  const [orderInfo, setInfo] = useState(null);

  const [freightBill, setFreightBill] = useState(null);

  const [freightBillName, setFreightBillName] = useState(null);

  const [pageType] = useState(Tracking.PageType.Order);
  const [orderSerialNumber, setOrderSerialNumber] = useState(null);

  const options = {
    cachePolicy: "no-cache",
    mode: "cors",
  };

  const { response, get } = useFetch(
    `${process.env.REACT_APP_API_URL}/v2`,
    options
  );

  const { response: freightBillResponse, get: getFreightBill } = useFetch(
    `${process.env.REACT_APP_API_URL}/v2`,
    options
  );
  const { t } = useTranslation();
  const loadOrderInfo = useCallback(async () => {
    if (!trackingID) {
      return;
    }

    const orderInfo = await get(trackingID);


    if (orderInfo.options?.allowDownloadingFreightBill) {
      const freightBillInfo = await getFreightBill(
        `/${trackingID}/freight-bill`
      );

      if (freightBillResponse.ok) {
        setFreightBill(freightBillInfo);
      } else {
        setFreightBill(null);
      }
    }

    if (response.ok) {
      setOrderSerialNumber(orderInfo.serialNumber);

      setInfo(orderInfo);
      setFreightBillName(getFreightBillName(t, orderInfo.serialNumber));
    } else {
      setOrderSerialNumber(null);

      setNotFound(true);
    }
  }, [get, getFreightBill, response, freightBillResponse, trackingID, t]);

  // Get inital data on mount
  useEffect(() => {
    loadOrderInfo();
  }, [loadOrderInfo]);

  useEffect(() => {
    Tracking.updateGlobalProperties({
      [Tracking.Property.OrderSerialNumber]: orderSerialNumber,
      [Tracking.Property.PageType]: pageType,
    });

    if (!pageType || !orderSerialNumber) {
      return;
    }

    Tracking.trackPageViewEvent(Tracking.TrackingEvent.DetailsPageViewed);
  }, [pageType, orderSerialNumber]);

  // Register interval polling
  useInterval(() => loadOrderInfo(), notFound ? null : 60000);

  if (notFound) {
    return <NotFound />;
  } else if (orderInfo) {
    return (
      <MainSection>
        <div className="grid-x">
          <div className="cell medium-6">
            <div className="grid-container">
              <Header
                serialNumber={orderInfo.serialNumber}
                showComplete={orderInfo.status.delivered}
                showCarrier={true}
                carrierLogo={orderInfo.carrierLogo}
                carrierName={orderInfo.carrierName}
              />
              <InfoContainer>
                <PackageDescription
                  locationName={orderInfo.pickup.name}
                  packageDetails={orderInfo.packageDetails}
                />

                <Address
                  addressType="pickup"
                  address={orderInfo.pickup}
                  estimatedTime={orderInfo.estPickup}
                  completedAt={orderInfo.status.collected}
                  isNext={!orderInfo.status.pickedUp}
                  estimatesLive={orderInfo.estimatesLive}
                />

                <Address
                  addressType="delivery"
                  address={orderInfo.delivery}
                  estimatedTime={orderInfo.estDelivery}
                  completedAt={orderInfo.status.delivered}
                  isNext={!!orderInfo.status.collected}
                  estimatesLive={orderInfo.estimatesLive}
                />

                <DeliveryProof
                  deliveryProof={orderInfo.completionProof}
                ></DeliveryProof>

                {orderInfo.options?.allowDownloadingFreightBill &&
                  freightBill && (
                    <DownloadFreightBill
                      pdfFileBase64={freightBill}
                      fileName={freightBillName}
                    ></DownloadFreightBill>
                  )}

                <DriverDetails
                  driverDetails={orderInfo.driver}
                  deliveryLng={orderInfo.delivery.address.longitude}
                  deliveryLat={orderInfo.delivery.address.latitude}
                  pickupLng={orderInfo.pickup.address.longitude}
                  pickupLat={orderInfo.pickup.address.latitude}
                  showDelivery={!!orderInfo.status.collected}
                />
              </InfoContainer>
            </div>
          </div>
          <div className="cell medium-6">
            <StatusWrapper>
              <Status status={orderInfo.status} />
            </StatusWrapper>
          </div>
        </div>
      </MainSection>
    );
  } else {
    return <Loader />;
  }
};

export default OrderPage;
