import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Card from "../card/Card";
import styled from "styled-components";
import "react-responsive-modal/styles.css";
import ProductsList from "../productsList/ProductsList";
import { formatWeight } from "../../formatting/weight";
import { formatVolume } from "../../formatting/volume";
import { filterPackages } from "../../shared/functions";


const P = styled.p`
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.3px;
`;

const Wrapper = styled.div`
  cursor: pointer;
  z-index: 2;
  position: relative;
`;

const PackageDescription = ({ locationName, packageDetails }) => {
  const [modalOpen, setModalOpen] = useState(false);

  const { t } = useTranslation();

  const filteredPackages = filterPackages(locationName, packageDetails);

  return (
    <>
      <Wrapper onClick={() => setModalOpen(true)}>
        <Card className="text-center">
          <P>{t("ordered_by")}:</P>

          <h2>{locationName}</h2>

          <p className="card-description">{t("colli_description")}</p>

          <div className="package-details">
            <div className="detail">
              <span className="icon packages"></span>
              {`${packageDetails?.totalNumber ?? 0} ${t("colli")}`}
            </div>

            <div className="detail">
              <span className="icon weight"></span>
              {formatWeight(packageDetails?.totalWeight ?? 0)}
            </div>

            <div className="detail">
              <span className="icon volume"></span>
              {formatVolume(packageDetails?.totalVolume ?? 0)}
            </div>
          </div>
          <div className="right-arrow-icon"></div>
        </Card>
      </Wrapper>

      <ProductsList
        products={filteredPackages}
        isOpen={modalOpen}
        onOverlayClick={() => {
          setModalOpen(false);
        }}
      ></ProductsList>
    </>
  );
};

export default PackageDescription;
