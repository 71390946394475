import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { useFetch } from "use-http";
import styled from "styled-components";
import { useInterval } from "../hooks/useInterval";
import Status from "../components/status/Status";
import DriverDetails from "../components/driverDetails/DriverDetails";
import NotFound from "../components/NotFound/NotFound";
import Loader from "../components/loader/Loader";
import DeliveryAlternatives from "../components/deliveryAlternatives/DeliveryAlternatives";
import OtherStop from "../components/stop/OtherStop";
import DownloadFreightBill from "../components/downloadFreightBill/DownloadFreightBill";
import getFreightBillName from "../components/downloadFreightBill/functions";
import YourStop from "../components/stop/YourStop";
import Header from "../components/header/Header";
import DeliveryProof from "../components/deliveryProof/DeliveryProof";
import TimeWindows from "../components/timeWindows/TimeWindows";
import { useTranslation } from "react-i18next";
import * as Tracking from "../tracking/Tracking";

const MainSection = styled.section`
  min-height: 100vh;
`;

const InfoContainer = styled.div`
  padding: 0px 100px;

  @media screen and (max-width: 39.9375em) {
    & {
      padding: 0px 0px;
    }
  }

  @media screen and (min-width: 40em) and (max-width: 63.9375em) {
    & {
      padding: 0px 20px;
    }
  }
`;

const StatusWrapper = styled.div`
  background-color: ${(props) => props.theme.colors.resolutionBlue};
  height: 100%;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
  display: flex;
  align-items: center;
  padding: 0px 100px 0px 140px;
  min-height: 100vh;
  position: fixed;

  @media screen and (max-width: 39.9375em) {
    padding: 0px;
    border-radius: 16px 16px 0px 0px;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: auto;
    z-index: 999;
    min-height: auto;
    background: transparent;
  }
  @media screen and (min-width: 40em) and (max-width: 63.9375em) {
    & {
      padding: 0px 20px 0px 60px;
    }
  }
`;

const StopPage = () => {
  const { t } = useTranslation();

  const { trackingID } = useParams();

  const [notFound, setNotFound] = useState(false);

  const [stopInfo, setStopInfo] = useState(null);

  const [freightBill, setFreightBill] = useState(null);

  const [freightBillName, setFreightBillName] = useState(null);

  const [isEditable, setIsEditable] = useState(true);

  const [pageType, setPageType] = useState(null);
  const [orderSerialNumber, setOrderSerialNumber] = useState(null);

  const [updateInformationStatus, setUpdateInformationStatus] = useState({
    hasExecuted: false,
    loading: false,
    error: false,
  });

  const removeContactlessInfo = (info) => {
    info = { ...info };
    if (info.hasOwnProperty("contactlessAccepted")) {
      delete info.contactlessAccepted;
    }
    if (info.hasOwnProperty("accept_contactless")) {
      delete info.accept_contactless;
    }
    return info;
  };

  const updateIsEditable = (stopInfo, setIsEditable) => {
    // if stop is pickup and is picked up
    if (stopInfo?.yourPlace.type === "pickup" && stopInfo?.status.collected) {
      setIsEditable(false);
      return;
    }

    // if stop is delivery and is delivered
    if (stopInfo?.yourPlace.type === "delivery" && stopInfo?.status.delivered) {
      setIsEditable(false);
      return;
    }

    setIsEditable(true);
  };

  const options = {
    cachePolicy: "no-cache",
    mode: "cors",
  };

  const { response: responseV2, get: getV2 } = useFetch(
    `${process.env.REACT_APP_API_URL}/v2/stop`,
    options,
  );

  const { response: responseV1, post: postV1 } = useFetch(
    `${process.env.REACT_APP_API_URL}/stop`,
    options,
  );

  const { response: responsepV2 , put: putV2  } = useFetch(
    `${process.env.REACT_APP_API_URL}/v2/stop`,
    options,
  );

  const { response: freightBillResponse, get: getFreightBill } = useFetch(
    `${process.env.REACT_APP_API_URL}/v2/stop`,
    options,
  );

  const loadStopInfo = useCallback(async () => {
    if (!trackingID) {
      return;
    }

    const stopInfo = await getV2(trackingID);

    if (stopInfo.options?.allowDownloadingFreightBill) {
      const freightBillInfo = await getFreightBill(
        `/${trackingID}/freight-bill`,
      );

      if (freightBillResponse.ok) {
        setFreightBill(freightBillInfo);
      } else {
        setFreightBill(null);
      }
    }

    if (responseV2.ok) {
      const isPickup = stopInfo.yourPlace.type === "pickup";
      const pageType = isPickup
        ? Tracking.PageType.Pickup
        : Tracking.PageType.Delivery;

      setPageType(pageType);
      setOrderSerialNumber(stopInfo.orderSerialNumber);

      setStopInfo(stopInfo);
      setFreightBillName(getFreightBillName(t, stopInfo.orderSerialNumber));

      updateIsEditable(stopInfo, setIsEditable);
    } else {
      setPageType(Tracking.PageType.Pickup);
      setOrderSerialNumber(null);

      setNotFound(true);
      updateIsEditable(null, setIsEditable);
    }
  }, [
    getV2,
    getFreightBill,
    responseV2,
    freightBillResponse,
    trackingID,
    setIsEditable,
    t
  ]);

  // Get inital data on mount
  useEffect(() => {
    loadStopInfo();
  }, [loadStopInfo]);

  useEffect(() => {
    Tracking.updateGlobalProperties({
      [Tracking.Property.OrderSerialNumber]: orderSerialNumber,
      [Tracking.Property.PageType]: pageType,
    });

    if (!pageType || !orderSerialNumber) {
      return;
    }

    Tracking.trackPageViewEvent(Tracking.TrackingEvent.DetailsPageViewed);
  }, [pageType, orderSerialNumber]);

  // Register interval polling
  useInterval(() => loadStopInfo(), notFound ? null : 60000);

  const saveAdditionalInfo = async () => {
    setUpdateInformationStatus({
      ...updateInformationStatus,
      error: false,
      loading: true,
      hasExecuted: true,
    });

    await putV2(
      `${trackingID}/set-user-info`,
      removeContactlessInfo(stopInfo.recipientInfo),
    );

    setUpdateInformationStatus({
      ...updateInformationStatus,
      error: !responsepV2.ok,
      loading: false,
      hasExecuted: true,
    });

    if (responsepV2.ok) {
      setStopInfo({
        ...stopInfo,
      });

      Tracking.trackEvent(Tracking.TrackingEvent.DeliveryDetailsUpdated);
    }

    return responsepV2.ok;
  };

  const saveContactless = async (acceptContactless) => {
    setUpdateInformationStatus({
      ...updateInformationStatus,
      error: false,
      loading: true,
      hasExecuted: true,
    });

    await putV2(`${trackingID}/set-user-info`, {
      ...removeContactlessInfo(stopInfo.recipientInfo),
      accept_contactless: acceptContactless,
    });

    setUpdateInformationStatus({
      ...updateInformationStatus,
      error: !responsepV2.ok,
      loading: false,
      hasExecuted: true,
    });

    if (responsepV2.ok) {
      setStopInfo({
        ...stopInfo,
        recipientInfo: {
          ...stopInfo.recipientInfo,
          contactlessAccepted: acceptContactless,
        },
      });

      const deliveryForm = acceptContactless
        ? Tracking.DeliveryForm.Contactless
        : Tracking.DeliveryForm.Standard;

      Tracking.trackEvent(Tracking.TrackingEvent.DeliveryFormChanged, {
        [Tracking.Property.DeliveryForm]: deliveryForm,
      });
    }

    return responseV1.ok;
  };

  function getStatusText(status) {
    const statusTexts = {
      pending_pickup_rescheduling: "status.pendingPickUpReschedule",
      pickup_rescheduled: "status.rescheduledForPickUp",
      pending_delivery_rescheduling: "status.pendingDeliveryReschedule",
      delivery_rescheduled: "status.rescheduledForDelivery",
      returned_to_origin: "status.returnedToOrigin",
    };

    return statusTexts[status] || "Unknown status";
  }

  const saveImage = async (image) => {
    const formdata = new FormData();
    formdata.append("image", image.file);

    const data = await postV1(`${trackingID}/image`, formdata);

    if (responseV1.ok) {
      const urls = data.map((image) => image.download_url);

      setStopInfo({
        ...stopInfo,
        yourPlace: {
          ...stopInfo.yourPlace,
          imageUrls: [...(stopInfo.yourPlace.imageUrls ?? []), ...urls],
        },
      });
    }
  };

  const isOrderManualOnHold = (stopInfo) => {
    return stopInfo.isManual && stopInfo.isOnHold;
  };

  const allowDoNotDeliver = (stopInfo) => {
    return !stopInfo.status.assigned && !isOrderManualOnHold(stopInfo);
  };

  if (notFound) {
    return <NotFound />;
  } else if (stopInfo) {
    return (
      <MainSection>
        <div className="grid-x">
          <div className="cell medium-6">
            <div className="grid-container">
              {stopInfo.yourPlace.type === "pickup" ? (
                <Header
                  serialNumber={stopInfo.orderSerialNumber}
                  showComplete={stopInfo.status.collected}
                  showVisitAttempted={stopInfo.status.lastPickupAttempt}
                  completeText={t("order_picked_up_successfully")}
                  visitAttemptedText={t(
                    getStatusText(stopInfo.status.DisplayOrderStatus),
                  )}
                ></Header>
              ) : (
                <Header
                  serialNumber={stopInfo.orderSerialNumber}
                  showComplete={stopInfo.status.delivered}
                  showVisitAttempted={stopInfo.status.lastDeliveryAttempt}
                  visitAttemptedText={t(getStatusText("returned_to_origin"))}
                ></Header>
              )}

              <InfoContainer>
                {stopInfo.carrierName !== "SERVICEGROSSISTEN ØST AS AVD LIER" && (
                  <OtherStop
                    clientLogo={stopInfo.carrierLogo}
                    clientName={stopInfo.carrierName}
                    packageDetails={stopInfo.packageDetails}
                    otherStop={stopInfo.otherPlace}
                  />
                )}

                <YourStop
                  yourStop={stopInfo.yourPlace}
                  isEditable={isEditable}
                  images={stopInfo.yourPlace.imageUrls}
                  additionalInfo={{
                    doorbell: stopInfo.recipientInfo.doorbell,
                    floor: stopInfo.recipientInfo.floor,
                    instructions: stopInfo.recipientInfo.instructions,
                  }}
                  status={stopInfo.status}
                  onAdditionalInfoChange={(recipientInfo) => {
                    setStopInfo({
                      ...stopInfo,
                      recipientInfo: {
                        ...stopInfo.recipientInfo,
                        ...recipientInfo,
                      },
                    });
                  }}
                  updateStatus={updateInformationStatus}
                  onSave={() => saveAdditionalInfo()}
                  onImageSave={(image) => saveImage(image)}
                  isOrderManualOnHold={isOrderManualOnHold(stopInfo)}
                  allowDoNotDeliver={allowDoNotDeliver(stopInfo)}
                />

                {stopInfo.yourPlace.type === "delivery" &&
                  stopInfo.options?.allowContactlessDeliveries &&
                  isEditable && (
                    <DeliveryAlternatives
                      contactlessDelivery={
                        stopInfo.recipientInfo.contactlessAccepted
                      }
                      onContactlessChange={(contactlessAccepted) => {
                        saveContactless(contactlessAccepted);
                      }}
                    />
                  )}

                {stopInfo.yourPlace.type === "delivery" && (
                  <DeliveryProof
                    deliveryProof={stopInfo.yourPlace.completionProof}
                  ></DeliveryProof>
                )}

                <TimeWindows
                  stopType={stopInfo.yourPlace.type}
                  timeWindow={stopInfo.yourPlace.timeWindow}
                  onTimeWindowUpdated={() => loadStopInfo()}
                  allowChangingDeliveryInterval={
                    stopInfo.options?.allowChangingDeliveryInterval
                  }
                  allowDoNotDeliver={allowDoNotDeliver(stopInfo)}
                  isOrderManual={stopInfo.isManual}
                ></TimeWindows>

                {stopInfo.options?.allowDownloadingFreightBill &&
                  freightBill && (
                    <DownloadFreightBill
                      pdfFileBase64={freightBill}
                      fileName={freightBillName}
                    ></DownloadFreightBill>
                  )}

                {stopInfo.driver && (
                  <DriverDetails
                    driverDetails={stopInfo.driver}
                    deliveryLng={stopInfo.yourPlace.address.longitude}
                    deliveryLat={stopInfo.yourPlace.address.latitude}
                    pickupLng={stopInfo.yourPlace.address.longitude}
                    pickupLat={stopInfo.yourPlace.address.latitude}
                    showDelivery={stopInfo.yourPlace.type === "delivery"}
                    carrierName={stopInfo.carrierName}
                  />
                )}
              </InfoContainer>
            </div>
          </div>
          <div className="cell medium-6">
            <StatusWrapper>
              <Status status={stopInfo.status} />
            </StatusWrapper>
          </div>
        </div>
      </MainSection>
    );
  } else {
    return <Loader />;
  }
};

export default StopPage;
